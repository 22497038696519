import { useEffect } from 'react';
import ReactGA from 'react-ga';

const useGaTracking = () => {
    useEffect(() => {
        ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_CODE}`);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
}
export default useGaTracking
