import React, { useEffect, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import WebFont from 'webfontloader';

import './styles/App.css';
import useGaTracking from "./hooks/api/useGaTracking";

//container
const HomeContainer = React.lazy(() => import('./containers/home'));
const PrivacyPolicyContainer = React.lazy(() => import('./containers/privacy-policy'));
const PrivacyNoticeContainer = React.lazy(() => import('./containers/privacy-notice'));

function App() {
  useGaTracking();
  
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Montserrat:400,500,600', 'Inter:400,500,600']
      }
    });
  }, []);

  return (
    <Suspense fallback={<span>Loading...</span>}>
      <BrowserRouter>
        <div className={'relative app-container'}>
          <Routes>
            <Route path={'/'} element={<HomeContainer />} />
            <Route path={'/privacy-policy'} element={<PrivacyPolicyContainer />} />
            <Route path={'/privacy-notice'} element={<PrivacyNoticeContainer />} />
          </Routes>
        </div>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
